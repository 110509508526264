.text-sans {
  font-family: $font-family-sans !important;
  line-height: 1.45;
}

.text-light {
  font-weight: 300;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: 900;
}

.text-lead {
  font-size: $font-size-s;
  @include media('md') {
    font-size: $font-size-m;
  }
  @include media('lg') {
    font-size: $font-size-m;
  }
  @include media('xl') {
    font-size: $font-size-l;
  }
}

.text-brand {
  color: $brand-color;
}

.text-default {
  color: $text-color;
}

.text-small {
  font-size: $font-size-xs;
}

.text-no-decoration {
  text-decoration: none;
}

.text-justify {
  text-align: justify;
}