.flex {
  display: flex;
}

.flex--row {
  flex-direction: row;
}

.flex--column {
  flex-direction: column;
}

.flex--align-end {
  align-items: flex-end;
}