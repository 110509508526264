/*----------------------------------------*\
  GLOBAL SETTINGS
\*----------------------------------------*/

// Use this setting to prefix all the components classes
$namespace:                         '';

// Flip all left/right properties for right-to-left languages
$rtl:                               false;


/**
 * Colors
 */

$brand-color:                       #DE2222;

$alt-color-lightest:                #F8F8F8;
$alt-color-lighter:                 #E9E9E9;
$alt-color-light:                   #CCCCCC;
$alt-color:                         #B1B1B1;
$alt-color-dark:                    #919191;
$alt-color-darker:                  #616161;
$alt-color-darkest:                 #262626;


/**
 * Typography
 */


$font-family-default:               "Martel",
                                    Constantia,
                                    "Lucida Bright",
                                    Lucidabright,
                                    "Lucida Serif",
                                    Lucida,
                                    "DejaVu Serif",
                                    "Bitstream Vera Serif",
                                    "Liberation Serif",
                                    Georgia,
                                    serif;


$font-family-sans:                  "Rubik",
                                    Frutiger,
                                    "Frutiger Linotype",
                                    Univers,
                                    Calibri,
                                    "Gill Sans",
                                    "Gill Sans MT",
                                    "Myriad Pro",
                                    Myriad,
                                    "DejaVu Sans Condensed",
                                    "Liberation Sans",
                                    "Nimbus Sans L",
                                    Tahoma,
                                    Geneva,
                                    "Helvetica Neue",
                                    Helvetica,
                                    Arial,
                                    sans-serif;

$font-size-default:                 16px;
$font-size-small:                   .75rem;
$font-size-large:                   1.5rem;

$font-size-xxl:                     2.39em;
$font-size-xl:                      2.069em;
$font-size-l:                       1.477em;
$font-size-m:                       1.333em;
$font-size-s:                       1.000em;
$font-size-xs:                      0.750em;

$font-weight-normal:                300;
$font-weight-bold:                  900;

$line-height-default:               1.75;

$text-color:                        $alt-color-darkest;
$link-color:                        $brand-color;
$muted-color:                       $alt-color-darker;


/**
 * Spacings
 */

$ratio:                             1.61803398875;

$spacing-unit-default:              24px;
$spacing-unit-tiny:                 floor($spacing-unit-default / ($ratio * 2));
$spacing-unit-small:                floor($spacing-unit-default / $ratio);
$spacing-unit-large:                floor($spacing-unit-default * $ratio);
$spacing-unit-huge:                 floor($spacing-unit-default * ($ratio * 2));

$spacings:                          (
                                      'tight' 0,
                                      'tiny' $spacing-unit-tiny,
                                      'small' $spacing-unit-small,
                                      'large' $spacing-unit-large,
                                      'huge' $spacing-unit-huge
                                    );


/**
 * Responsiveness
 */

// Use EM media-queries for better browser consistency
// See http://zellwk.com/blog/media-query-units
$screen-xs-max:                     640px  / 16px * 1em;
$screen-sm-min:                     641px  / 16px * 1em;
$screen-sm-max:                     768px  / 16px * 1em;
$screen-md-min:                     769px  / 16px * 1em;
$screen-md-max:                     1024px / 16px * 1em;
$screen-lg-min:                     1025px / 16px * 1em;
$screen-lg-max:                     1439px / 16px * 1em;
$screen-xl-min:                     1440px / 16px * 1em;

$breakpoints-default:               (
                                      'sm' '(min-width: #{$screen-sm-min})',
                                      'md' '(min-width: #{$screen-md-min})',
                                      'lg' '(min-width: #{$screen-lg-min})',
                                      'xl' '(min-width: #{$screen-xl-min})',
                                    );
$breakpoints-desc:                  (
                                      'xs' '(max-width: #{$screen-xs-max})',
                                      'sm' '(max-width: #{$screen-sm-max})',
                                      'md' '(max-width: #{$screen-md-max})',
                                      'lg' '(max-width: #{$screen-lg-max})',
                                    );


/**
 * Miscellaneous
 */

$border-radius-default:             4px;
$border-radius-small:               2px;
$border-radius-large:               6px;


/*----------------------------------------*\
  COMPONENTS
\*----------------------------------------*/

/**
 * Buttons
 */

$btn-padding:                       $spacing-unit-small/$ratio $spacing-unit-small;
$btn-color:                         inherit;
$btn-font-size:                     inherit;
$btn-font-family:                   inherit;
$btn-border:                        1px solid transparent;
$btn-bkg:                           transparent;
$btn-border-radius:                 $border-radius-default;

$btn-small-padding:                 $spacing-unit-tiny/$ratio $spacing-unit-tiny;
$btn-small-font-size:               $font-size-small;
$btn-small-border:                  $btn-border;
$btn-small-border-radius:           $border-radius-small;

$btn-large-padding:                 $spacing-unit-default/$ratio $spacing-unit-default;
$btn-large-font-size:               $font-size-large;
$btn-large-border:                  $btn-border;
$btn-large-border-radius:           $border-radius-large;

$btn-default-color:                 white;
$btn-default-bkg-color:             $alt-color-darkest;
$btn-default-hover-bkg-color:       lighten($btn-default-bkg-color, 3%);
$btn-default-active-bkg-color:      darken($btn-default-bkg-color, 5%);

$btn-primary-color:                 white;
$btn-primary-bkg-color:             $brand-color;
$btn-primary-hover-bkg-color:       lighten($btn-primary-bkg-color, 8%);
$btn-primary-active-bkg-color:      darken($btn-primary-bkg-color, 5%);


/**
 * Forms
 */

$field-padding:                     $spacing-unit-small/$ratio $spacing-unit-small;

$field-color:                       inherit;
$field-font-size:                   inherit;
$field-font-family:                 inherit;
$field-line-height:                 $line-height-default;

$field-bkg-color:                   white;
$field-border:                      1px solid $alt-color-light;
$field-focus-border-color:          $alt-color-darker;
$field-border-radius:               $border-radius-default;

$field-disabled-bkg-color:          $alt-color-lighter;
$field-disabled-color:              $alt-color;

$field-small-padding:               $spacing-unit-tiny/$ratio $spacing-unit-tiny;
$field-small-font-size:             $font-size-small;
$field-small-border:                $field-border;
$field-small-border-radius:         $border-radius-small;

$field-large-padding:               $spacing-unit-default/$ratio $spacing-unit-default;
$field-large-font-size:             $font-size-large;
$field-large-border:                $field-border;
$field-large-border-radius:         $border-radius-large;

$field-help-color:                  $alt-color-light;

$form-group-spacing:                $spacing-unit-small;

/**
 * Lists
 */

$list-separator-style:              1px solid $alt-color-lighter;


/**
 * Box
 */

$box-default-color:                 inherit;
$box-default-bkg-color:             $alt-color-lighter;

$box-primary-color:                 white;
$box-primary-bkg-color:             $brand-color;


/**
 * Widths
 */

$widths-columns:                    6,5,4,3,2,1;
$widths-breakpoints:                $breakpoints-default;


/**
 * Media responsive
 */

$media-collapse:                    $screen-sm-max;


/**
 * Container
 */

$container-gutter-width:            0;
$container-max-width:               1680px;


/**
 * Table responsive
 */

$table-responsive-collapse:         $screen-sm-max;


$hover-image-transition:            0.32s ease-in-out;