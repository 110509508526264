p {
  overflow: hidden;
}
.article {
  background: white;
}

.section {
  margin: $spacing-unit-large 0;
  @include media('lg') {
    margin: $spacing-unit-huge 0;
  }
}

.section--lvl5 {
  z-index: 5;
}

.container {
  padding: 0 $spacing-unit-small;
  @include media('sm') {
    padding: 0 $spacing-unit-large;
  }
  @include media('lg') {
    padding: 0 $spacing-unit-huge;
  }
}

.container--full {
  max-width: none;
}

.area-map-teaser {
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform: translate(-10%, -40%);
  }
}

.section--separated {
  border-bottom: $list-separator-style;
  padding-bottom: $spacing-unit-large;
  @include media('lg') {
    padding-bottom: $spacing-unit-huge;
  }
}

.room-floor-plans {
  img {
    width: 100%;
  }
}

.house-footer {
  position: sticky;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.area-hero {
  position: relative;
  min-height: 100vh;
  @include media('sm') {
    min-height: 60vh;
  }
  @include media('md') {
    min-height: 100vh;
  }
  @include media('lg') {
    min-height: calc(100vh - 17rem);
  }
}

.area-hero__content {
  position: relative;
  z-index: 1;
}

.area-hero__image {
  flex: 1;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include media('sm') {
    height: 60vh;
  }
  @include media('md') {
    height: 100vh;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
  }
}

.room {
  position: relative;
  display: flex;
  @include media('sm') {
    display: flex;
  }
}

.room__image {
  width: 3rem;
  height: 3rem;
  flex: 0 0 3rem;
  order: 4;
  @include media('lg') {
    flex: 0 0 200px;
    height: 8rem;
    order: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.home-hero {
  height: calc(100vh - 140px);
  @include media('lg') {
    height: calc(100vh - 162px);
  }
}

.home-hero__content {
  flex: 0 0 auto;
}

.home-hero__image {
  flex: 1;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


$grid-gutter-xs: 2rem;
$grid-gutter-sm: 2.5rem;
$grid-gutter-md: 3rem;
$grid-gutter-lg: 3.5rem;
$grid-gutter-xl: 4rem;

.grid--huge {
  margin-left: -$grid-gutter-xs;
  > .grid__item {
    padding-left: $grid-gutter-xs;
  }
  &.grid--multiline > .grid__item {
    padding-top: $grid-gutter-xs;
  }
  @include media('sm') {
    margin-left: -$grid-gutter-sm;
    > .grid__item {
      padding-left: $grid-gutter-sm;
    }
    &.grid--multiline > .grid__item {
      padding-top: $grid-gutter-sm;
    }
  }
  @include media('md') {
    margin-left: -$grid-gutter-md;
    > .grid__item {
      padding-left: $grid-gutter-md;
    }
    &.grid--multiline > .grid__item {
      padding-top: $grid-gutter-md;
    }
  }
  @include media('lg') {
    margin-left: -$grid-gutter-lg;
    > .grid__item {
      padding-left: $grid-gutter-lg;
    }
    &.grid--multiline > .grid__item {
      padding-top: $grid-gutter-lg;
    }
  }
  @include media('xl') {
    margin-left: -$grid-gutter-xl;
    > .grid__item {
      padding-left: $grid-gutter-xl;
    }
    &.grid--multiline > .grid__item {
      padding-top: $grid-gutter-xl;
    }
  }
}

.area-map {
  position: relative;
  z-index: 4;
  margin-top: -0rem;
  box-shadow: 0 16px 23px rgba($alt-color-dark, 0.12);
  img {
    max-width: 100%;
    min-width: 100%;
  }
}

.travel-tip {
  &:hover {
    .travel-tip__image img {
      transform: scale(1.05);
    }
  }
}

.travel-tip__image {
  overflow: hidden;
  img {
    transition: $hover-image-transition;
    max-width: 100%;
    min-width: 100%;
  }
}

.contact-infos {
  @include media('sm') {
    display: flex;
  }
}

.contact-map {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba($alt-color, 0.3);
  }
  img {
    width: 100%;
  }
} 


.contact-form {
}

.footer {
  background: $alt-color-darkest;
  color: white;
  @include media('sm', $breakpoints-desc) {
    padding-bottom: 5rem !important;
  }

  a {
    color: white;
    text-decoration: none;
    border-bottom: 2px solid rgba(white, 0.2);
  }
} 

.footer__content {
  @include media('md') {
    display: flex;
    .right {
      flex: 1;
      text-align: right;
    }
  }
}
