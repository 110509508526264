.mrgla {
  margin-left: auto;
}

.mrgra {
  margin-right: auto;
}

.mrgta {
  margin-top: auto;
}

.w-100 {
  width: 100%;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.relative {
  position: relative;
}

.index-5 {
  z-index: 5;
}

.index-4 {
  z-index: 4;
}

.index-3 {
  z-index: 3;
}

.index-2 {
  z-index: 2;
}

.index-1 {
  z-index: 1;
}