.navigation {
  // border-bottom: $list-separator-style;
}

.navigation__logo {
  svg {
    width: 7rem;
    display: block;
    @include media('sm') {
      width: 9rem;
    }
    @include media('md') {
      width: 11rem;
    }
    @include media('lg') {
      width: 14rem;
    }
  }
}

.navigation__menu {
  @include media('sm', $breakpoints-desc) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-top: $list-separator-style;
    z-index: 100;
    ul {
      justify-content: space-between;
    }
  }
}