/*----------------------------------------*\
  BOX
\*----------------------------------------*/

/**
 * Styles
 */

.#{$namespace}box--border {
  border: $list-separator-style;
}
