html {
  font-weight: $font-weight-normal;

  font-size: 100% * 16px / 16px;

  hyphens: auto;
}

body {
  font-size: 1rem;
  @include media('sm') {
    font-size: rem(18px);
  }
  @include media('lg') {
    font-size: rem(20px);
  }
}

@include headings {
  margin: 1.414em 0 .5em;
  font-weight: inherit;
  line-height: 1.2;
}

h1,
.h1 {
  margin-top: 0;
  font-size: $font-size-m;
  @include media('sm') {
    font-size: $font-size-l;
  }
  @include media('md') {
    font-size: $font-size-l;
  }
  @include media('lg') {
    font-size: $font-size-xl;
  }
  @include media('xl') {
    font-size: $font-size-xxl;
  }
}

h2,
.h2 {
  font-size: $font-size-m;
  @include media('md') {
    font-size: $font-size-l;
  }
  @include media('lg') {
    font-size: $font-size-l;
  }
}

h3,
.h3 {
  font-size: $font-size-m;
}

h4,
.h4 {
  font-size: $font-size-s;
}

h5,
.h5 {
  font-size: $font-size-xs;
}

h6,
.h6 {
  font-size: $font-size-xs;
}
