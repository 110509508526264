/*----------------------------------------*\
  GALLERY
\*----------------------------------------*/

/**
 * Styles
 */

$gallery-nav-gutter: 1rem;

.hero-gallery {
  position: relative;
  .tns-nav {
    display: flex;
    margin-top: 0.5rem;
    margin-right: -$gallery-nav-gutter;
    @include media('md') {
      margin-top: 1.5rem;
    }
    button {
      @extend %btn;
      padding: 0;
      height: rem(5px);
      background: $alt-color-lighter;
      flex: 1;
      border-radius: 0;
      margin-right: $gallery-nav-gutter;
      border: 0;
      &.tns-nav-active {
        background: $alt-color;
      }
    }
  }
  .tns-controls {
    @include media('md', $breakpoints-desc) {
      display: none;
    }
    button {
      @extend %btn;
      position: absolute;
      top: 0;
      font-size: 0;
      z-index: 2;
      width: 2rem;
      height: 100%;
      padding: 0;
      border: 0;
      &:hover {
        &:before {
          background: rgba(black, 0.4);
        }
      }
      &:before {
        font-size: 2rem;
        line-height: 4.25rem;
        position: absolute;
        transform: translateY(-50%);
        width: 2rem;
        height: 4rem;
        color: white;
        background: rgba(black, 0.2);
        top: 50%;
        left: 0;
        z-index: 4;
      }
      &[data-controls=prev] {
        left: 0;
        &:before {
          content: "<";
        }
      }
      &[data-controls=next] {
        right: 0;
        &:before {
          content: ">";
        }
      }
    }
  }
}

.tns-outer {
  overflow: hidden;
}
